import React, { useEffect, useState, Suspense, lazy } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, useLocation, useHistory } from "react-router-dom";
import { Container, Typography, useTheme } from "@material-ui/core";
import db from "./utils/db";
import Dexie from "dexie";
import Footer from "./Footer/Footer";

const Login = lazy(() => import("./Login/Login"));
const LockScreen = lazy(() => import("./Login/LockScreen"));
const Dashboard = lazy(() => import("./Dashboard/Dashboard"));
const AccountDetails = lazy(() => import("./AccountDetails/AccountDetails"));
const AddTransaction = lazy(() => import("./AddTransaction/AddTransaction"));

function App() {
  const theme = useTheme();
  const [showUi, setShowUi] = useState(false);
  const [wallpaper, setWallpaper] = useState({ wallpaper: "", opacity: 0 });

  useEffect(() => {
    if (window.innerWidth <= theme.breakpoints.width("sm")) {
      setShowUi(true);
    } else {
      setShowUi(false);
    }
    db.settings
      .get(1)
      .then((res) => {
        if (res) {
          setWallpaper(res);
        }
      })
      .catch((err) => {});
    db.settings.hook("creating", async (key, data) => {
      try {
        setWallpaper(data);
      } catch (err) {
        setWallpaper(data);
      }
    });
  }, []);

  return (
    <>
      <Container maxWidth="xs">
        {wallpaper.wallpaper && (
          <div
            style={{
              backgroundImage: `url(${wallpaper.wallpaper})`,
              height: "100vh",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              zIndex: -1,
              opacity: wallpaper.opacity || 0.5,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          ></div>
        )}
        <Router>
          <PrivateRoutes />
        </Router>
      </Container>
      <Footer />
    </>
  );
}

function PrivateRoutes() {
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== "/") {
      try {
        Dexie.exists("ManagementDB").then((isExists) => {
          if (isExists) {
            if (db.isOpen()) {
              db.appKey.toArray().then(async (data) => {
                try {
                  if (data.length) {
                    const appKey = data[0].appKey;
                    if (!appKey) {
                      await db.delete();
                      history.replace({ pathname: "/" });
                    }
                  } else {
                    history.replace({ pathname: "/" });
                  }
                } catch (err) {}
              });
            } else {
              history.replace({ pathname: "/" });
            }
          } else {
            history.replace({ pathname: "/" });
          }
        });
      } catch (err) {}
    }
  }, [location.pathname]);
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Loading...</Typography>
        </div>
      }
    >
      <Switch>
        <Route path="/" exact component={Login} />
        <Route path="/lock" exact component={LockScreen} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/account/:id" component={AccountDetails} />
        <Route exact path="/account/:id/transaction" component={AddTransaction} />
        <Route exact path="/account/:id/transaction/:transactionId" component={AddTransaction} />
      </Switch>
    </Suspense>
  );
}

export default App;
