import React from "react";
import { Grid } from "@material-ui/core";
import appLogo from "../assets/logo.png";

export default function Footer() {
  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justify="space-between"
      alignItems="center"
      style={{
        padding: "0px 10px 10px 10px",
        position: "fixed",
        bottom: 0,
        backgroundColor: "white",
      }}
    >
      <Grid item>inspironiks &copy; 2020</Grid>
      <Grid item>
        <img src={appLogo} style={{ width: "10px" }} />
      </Grid>
      <Grid item>
        <a href="https://inspironiks.com">www.inspironiks.com</a>
      </Grid>
    </Grid>
  );
}
