import Dexie from "dexie";

let db = new Dexie("ManagementDB");
db.version(9).stores({
  accounts: "++id, title, createdAt, amount, modifiedAt",
  details: "++id, accountId, name, amount, type, createdAt, transactionDate",
  appKey: "++id",
  settings: "++id",
});
db.open();

export default db;
